import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation} from 'react-router-dom';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Loading from '../components/loader';
// import { GoogleLogin } from 'react-google-login';
import {useDispatch} from "react-redux"
import { login } from "../store/slices/authSlice";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


export default function RegisterUser() {
  const [formData, setFormData] = useState({name:'', email: '', password: '' });
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const plan = location.state.plan;
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://digisolvertechnologies.in/send-otp', { email: formData.email });
      if (response.status === 200) {
        setOtpSent(true);
      }
    } catch (error) {
      console.error('Failed to send OTP:', error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRegister = async () => {
 

    try {
      // Replace 'https:digisolvertechnologies.in/register' with your actual registration API endpoint
      const response = await axios.post('https://digisolvertechnologies.in/users', formData);

      // Check if the registration was successful
      if (response.status === 201) {
        // setIsLoading(false)
        console.log('Registration successful!');
        dispatch(login(response.data.user.name))
        localStorage.setItem("accessToken", response.data.token);
        navigate("/user/socialAccounts")
        // setRedirectToHome(true)
        // You may want to redirect the user or perform other actions after successful registration
      } else {
        console.error('Registration failed:', response.data);
      }
    } catch (error) {
      console.error('An error occurred during registration:', error.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://digisolvertechnologies.in/verify-otp', { email: formData.email, otp });
      if (response.status === 200) {
        // navigate("/", { state: { email: formData.email, password: formData.password } });
        handleRegister(formData)
      }
    } catch (error) {
      console.error('Failed to verify OTP:', error.message);
    } finally {
      setIsLoading(false);
    }
  };
  // useEffect(()=>{
  //   if(otp.length === 6){
  //     handleVerifyOtp()
  //   }
  // },[otp])

  return (
    <div className='bgHero max-h-[100vh]'>
    <Helmet>
        <title>Register - SocialSyncHub</title>
        <meta
          name="description"
          content="Create an account on SocialSyncHub to start managing all your social media accounts in one place."
        />
        <meta
          name="keywords"
          content="register, sign up, social media management, SocialSyncHub, social media tool"
        />
        <link rel="canonical" href="https://socialsynchub.com/registerUser" />
      </Helmet>
    <Header/>
    <div className='flex w-screen '>
    <div className='flex flex-col justify-between px-4 lg:px-20 bg-white py-12 lg:py-24 h-screen w-full lg:w-[30%]'>
    <div className="flex  flex-col  rounded-md ">
      <div className="">
        
        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Register account
        </h2>
      </div>

      <div className="">
        {!otpSent ? (
          <form className="flex flex-col gap-4" onSubmit={handleSendOtp}>
             <div>
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  // autoComplete="email"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-blue-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-blue-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-blue-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white  hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                Send OTP
              </button>
            </div>
          </form>
        ) : (
          <form className="flex flex-col gap-8" onSubmit={handleVerifyOtp}>
            <div>
              <label htmlFor="otp" className="block text-sm font-medium leading-6 text-gray-900">OTP</label>
              <div className="mt-2">
                <input
                  id="otp"
                  name="otp"
                  type="text"
                  required
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white  hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Verify OTP
              </button>
            </div>
          </form>
          
        )}
              <p className="mt-8 text-center text-sm text-gray-500">
              Already a member?{' '}
              <Link to="/signin" className="font-semibold leading-6 text-blue-600 hover:text-gray-500">
              Login
              </Link>
            </p>
      </div>
    </div>
    <Footer/>
    
    </div>
    <div className='w-3/4 h-screen bg-violet-500 p-20 hidden  lg:block'></div>
    </div>
    
    {isLoading && <Loading/>}
  </div>
  );
}
