import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Features from '../components/features';


const features = [
  {
    title: "Multi-Channel Posting",
    description: "Effortlessly publish content across multiple social media platforms from a single, unified dashboard. No need to switch between accounts or apps—simply create your content and distribute it to all your channels at once. ",
    videoSrc: '/img/accounts.png',
     
  },
  {
    title: "Scheduling",
    description: "Plan, schedule, and automate your posts with our intuitive scheduling tool. Use the drag-and-drop calendar interface to plan posts in advance and maintain a consistent social media presence. ",
    videoSrc: '/img/scheduleMockup.png'
  },
  {
    title: "Content Calendar",
    description: "Gain full visibility of your content strategy with our integrated content calendar Plan ahead, adjust your content, and track your progress—all from one place.",
    videoSrc: '/img/calender.png'
  }
];


export default function Home() {
  const animatedRef = useRef(null);
  const imgRef = useRef(null);
  const [heroImgHover, setHeroImgHover] = useState(false)
  const mouseEnter = ()=>{
setHeroImgHover(true)
  }
  const mouseExit = ()=>{
setHeroImgHover(false)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animated');
            observer.unobserve(entry.target); // Stop observing after animation is triggered
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (animatedRef.current) {
      observer.observe(animatedRef.current);
    }
    
    if (imgRef.current) {
      observer.observe(imgRef.current); // Observe the image as well
    }

    return () => {
      if (animatedRef.current) {
        observer.unobserve(animatedRef.current);
      }
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div className='bgHero'>
      <div className='sticky top-0'>
      <Header />
      </div>
     
      <div id='/' className='flex flex-col px-4 lg:px-40 py-4 md:py-20'>
        <div className='flex flex-col gap-12 lg:gap-0 lg:flex-row items-center justify-center'>
          
            <div className='flex flex-col items-start justify-center text-gray-800 z-30 max-w-xl'>
              <p className='text-5xl md:text-7xl font-bold text-gray-800 text-start mb-4  inria-sans-bold' >
                Manage <span className=' '>Social Media</span> From <span className=''>One Place</span>
              </p>
              {/* <p className='text-md max-w-md w-fit ' >
                Schedule and Post Across Multiple Social Media Platforms!
              </p> */}
              <Link to={'/registerUser'} className='cta mt-4'>
                Get Started Free
              </Link>
            </div>
          
       
       
           
            <img
              src='/img/calender.png '
              alt='Social media scheduling'
              className='bg-slate-100 rounded-md shadow-lg z-30 max-w-[380px] lg:max-w-[600px] px-2  hero-img '
              ref={imgRef}
              onMouseEnter={mouseEnter}
              onMouseOut={mouseExit}
            />
            
         
          
        </div>
      </div>
      {/* <div className={`absolute flex gap-4 justify-center top-[-300px] left-0 right-0 `}>
            <img src='/img/socialmediaIcons/fbButton.png' className={` w-12  transition-all duration-700  ${heroImgHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[600px]'}`} />
            <img src='/img/socialmediaIcons/instaButton.png' className={`w-12  transition-all duration-700 delay-200 ${heroImgHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-x-[600px]  translate-y-[600px] ' }`} />
            <img src='/img/socialmediaIcons/linkedin.png' className={`w-12  transition-all duration-700  delay-500 ${heroImgHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[600px]'}`} />
            <img src='/img/socialmediaIcons/Xbutton.png' className={`w-12  transition-all duration-700 ${heroImgHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[600px]'}`} />
            </div>  */}
      <div className='flex flex-col justify-center items-center gap-12 bgSec2 py-32 px-6'>
        <h3 className='text-4xl md:text-5xl inria-sans-bold max-w-xl text-center'>
          Efficiently <span className=''>Schedule</span> Your Posts
       
        </h3>
        <img src='/img/scheduleMockup.png' className='bg-slate-100 rounded-md shadow-lg z-30 w-[100%] lg:w-[50%]' />
      </div>

      {/* <div className='bgFeature p-4'  id='features'>
        <Features />
      </div> */}
      <section className='featuresSection flex flex-col items-center px-6 lg:px-20'>
        {features.map((feature,index)=>(
          <div className={`featureContainer flex ${index%2===1 ? ' lg:flex-row-reverse ' : 'lg:flex-row'} flex-col justify-center gap-20 items-center w-full  py-20`}  key={index}>
            <div className=''>
              <h3 className='text-5xl inria-sans-bold mb-3'>{feature.title}</h3>
              <p className='mb-12 text-xl leading-9  max-w-lg'>{feature.description}</p>
              <Link to={'/registerUser'} className='cta '>
                Get Started Free
              </Link>
            </div>
            <img src={feature.videoSrc} className=' max-w-[360px] lg:max-w-[800px] border-1 border-gray-200 shadow-lg'/>
          </div>
        ))}
      </section>
<section className='py-20'></section>
      {/* <div className='flex flex-col gap-4 items-center justify-center py-20 w-full bgPrimary px-4'>
        <h2 className='text-4xl font-bold text-center'>Start scheduling your posts for free</h2>
        <Link to={'/registerUser'} className='cta'>
          Start Scheduling
        </Link>
      </div> */}

      <Footer />
    </div>
  );
}
