import React, { useEffect, useState } from 'react';
import {  NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserNav from './userNav';  // Make sure the path is correct
// import { HashLink as Link } from "react-router-hash-link";
import { HashLink as  Link} from "react-router-hash-link";
import HeaderMobile from './headerMobile';
// import { ReactComponent as logoSvg } from ".../logo.svg";
// import { Link } from 'react-router-dom';


export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const userAuth = useSelector((state) => state.auth.isAuthenticated);
  const userName = useSelector((state) => state.auth.userName);
  const [screenWidth,setScreenWidth] = useState(window.innerWidth)
  const navs = [
    // {navTitle: 'Pricing', navRoute: '/pricing'},
    { navTitle: 'About', navRoute: '/about' },
    // { navTitle: 'Features', navRoute: '#features' },
  ];

  const handleBurgerMenu = () => {
    let temp = isMenuOpen
    setIsMenuOpen(!temp);
  };

  const closeMenu = () => {
    console.log("close menue")
    setIsMenuOpen(false);
  };
  useEffect(()=>{
    setScreenWidth(window.innerWidth)
  },[window.innerWidth])

  return (
    <>
    <div className='z-40  w-full  bgHeader'>
      <div className='flex flex-row justify-between items-center px-1 lg:px-40 '>
        <div className=' flex justify-center items-center'>
          <NavLink to={'/'}>
            <img src='logo.png' className='w-32 md:w-48' alt='Logo' />
            {/* <h3 className='text-white text-2xl font-bold'>Timingo.com</h3> */}
            {/* <Logo/> */}
            {/* <logoSvg/> */}
          </NavLink>
        </div>
    
        {/* <Link to={'/registerUser'} className='md:hidden cta'>
          Start Now
        </Link> */}
          <div className="lg:hidden">
                <button id="burger-menu" className="text-black focus:outline-none" onClick={handleBurgerMenu}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-max-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
        
        
        
        
       
        <nav className='hidden lg:flex flex-row  my-2 rounded-3xl justify-center items-center bg-white'>
       
          {navs.map((nav, index) => (
            <NavLink
              className='mx-4 my-2 p-2 text-black  rounded list-none'
              to={nav.navRoute}
              key={index}
            >
              {nav.navTitle}
            </NavLink>
          ))}
            <a
              className='mx-4 my-2 p-2 text-black  rounded list-none'
              href='/#features'
            >
            Features
            </a>
              <Link
              className='mx-4 my-2 p-2 textSecondary  rounded list-none'
              to={'/signin'}
           
            >
              Sign In
            </Link>
           <Link to={'/registerUser'} className='cta'>
          Register
        </Link>
        </nav>
      </div>
      {/* {isMenuOpen && <div className='block lg:hidden fixed top-0 w-full '><HeaderMobile navs={navs} userAuth={userAuth} closeMenu={closeMenu}/> </div>}  */}
      
    </div>

    <nav className={` ${isMenuOpen ? 'flex flex-col animateBgMobileEnter' : 'hidden'} justify-between  items-start lg:hidden py-2 overflow-hidden h-[92vh] z-40  top-[47.5px] bg-white w-screen  `}>
           <div className='flex flex-col w-full'>
           {navs.map((nav, index) => (
            <div  key={index} className='lineThin'>
                <NavLink
                  className='text-xl  items-center m-2 p-2  text-gray-800 hover:text-gray-600 transition-all duration-300'
                  to={nav.navRoute}
                  smooth
                 
                  onClick={ userAuth ? null : closeMenu}
                  // onClick={closeMenu}
                >
                  {nav.navTitle}
                </NavLink>
                </div>
              ))}
              <div className='lineThin'>
              <a
              className='m-2 p-2 text-gray-800  text-xl  rounded list-none'
              href='/#features'
            >
            Features
            </a>
              </div>
                 
            <div className='lineThin'>
          
</div>
            
         
           </div>
           <div className='flex flex-col py-4 gap-2 items-center w-full px-4'>
           <Link
              className='m-2 p-2 textPrimary  rounded list-none text-xl'
              to={'/signin'}
           
            >
              Sign In
            </Link>
           <Link to={'/registerUser'} className='cta w-full mt-4 text-center'>
                Get Started Free
              </Link>
           </div>
          
         
            </nav>
   </>
  );
}
